@import "../../scss/utils.scss";

.Screen-absolute-full {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Screen-full {
  width: 100%;
  height: 100%;
}

.Screen-left-half, .Screen-right-half {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: -1;

  @include sm {
    width: 100%;
  }
}

.Screen-right-half {
  right: 0;
}

.Screen-left-half {
  left: 0;
}

.Screen-full-height {
  height: 100%;
}

.gradient-layout {
  height: 100%;

  @include sm {
    height: 100vh;
  }
}
