/*
 * Typography
 * ======================================================================== */

// $font-family-base: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Lucida Grande", sans-serif;
$font-family-base: 'IBM Plex Sans', sans-serif;

/*
 * Colors
 * ======================================================================== */

$white:       #ffffff !default;
$white-50:    rgba(255, 255, 255, 0.5) !default;
$light:       #F5F5F5 !default;
$black:       #000000 !default;
$black-50:    rgba(0, 0, 0, 0.5) !default;
$dark:        #13151A !default;
$darkest:     #0F1014 !default;
$light-steal: #EBEBF0 !default;
$dark-steal:  #252933 !default;
$dark-light:  #37393B !default;
$blue:        #0d6efd !default;
$grey:        #808085 !default;
$grey-50:     #80808580 !default;
$gray-light:  #C8C7CC !default;
$green:       #05C46B !default;
$yellow:      #FFA800 !default;

:export {
  white:      $white;
  white-50:   $white-50;
  light:      $light;
  black:      $black;
  dark:       $dark;
  dark-steal: $dark-steal;
  blue:       $blue;
  grey:       $grey;
  grey-50:    $grey-50;
  gray-light: $gray-light;
  green:      $green;
  yellow:     $yellow;
}

/*
 * Layout
 * ======================================================================== */

$max-content-width: 1000px;

/*
 * Media queries breakpoints
 * ======================================================================== */

$screen-sm:  576px  !default;
$screen-md:  768px  !default;
$screen-lg:  992px  !default;
$screen-xl:  1200px !default;
$screen-xxl: 1400px !default;

@mixin sm  { @media (max-width: $screen-md)  { @content; } }
@mixin md  { @media (min-width: $screen-md)  { @content; } }
@mixin lg  { @media (min-width: $screen-lg)  { @content; } }
@mixin xl  { @media (min-width: $screen-xl)  { @content; } }
@mixin xxl { @media (min-width: $screen-xxl) { @content; } }

@function rem($size) {
  @return $size / 16px * 1rem;
}

//@mixin font-size($size) {
//  font-size: $size;
//  font-size: rem($size);
//}
