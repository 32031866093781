@import "../../scss/utils";

.Contact-email {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contact-email-link {
  text-decoration: none;
  color: transparent;
}

.Contact-email-text {
  font-size: 24px;
  font-weight: 400;
  padding-left: 20px;
  background: linear-gradient(90deg, #FAFF00 0.97%, #00FFA3 52.74%, #00C2FF 101.46%);
  background-clip: text;
  text-decoration: none;

  @include md {
    font-size: 82px;
  }
}

.Contact-info {
  font-size: 20px;
  font-weight: 400;
  color: $grey;
  text-align: center;
  margin-top: 70px;

  @include md {
    margin-top: 100px;
  }
}
