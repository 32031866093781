@import "../../scss/utils";

.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  border: 1px solid $grey-50;
  overflow: hidden;
  padding: 40px 24px 24px;

  @include md {
    padding: 40px 32px 32px;
  }

  @include lg {
    padding: 70px 52px 52px;
  }
}

.Card-start {
  margin-left: 0;
}

.Card-end {
  margin-right: 0;
}

.Card-title {
  padding: 52px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 42px;
  color: #FFFFFF;
  z-index: 1;
}

.Card-title--big {
  font-size: 36px;
  line-height: 47px;
  font-weight: 600;
}

.Card-info {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  z-index: 1;

  @include sm {
    font-size: 14px;
    line-height: 18px;
  }
}


.Card-dark {
  background: $black;
}
