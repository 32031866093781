@import "../../scss/utils";

.Edge {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.Edge-header,
.Edge-bottom {
  padding: 48px 0;

  @include md {
    padding: 80px 0;
  }
}

.Edge-bg {
  display: flex;
  justify-content: space-between;
}

.Edge-start-corner,
.Edge-end-corner {
  background-size: contain;
  background-repeat: no-repeat;
}

.Edge-start-rest,
.Edge-end-rest {
  flex-grow: 1;
}

.Edge-center {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  @include sm {
    margin-bottom: 50px;
  }

  @include md {
    margin-bottom: 80px;
  }
}

.Edge-text {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

  @include md {
    font-size: 36px;
    line-height: normal;
    width: 80%;
  }
}

.Edge-content {
  display: flex;
  color: #ffffff;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
  }
}

.Edge-info {
  width: 100%;

  @include md {
    width: 50%;
  }
}

.Edge-content-start,
.Edge-content-end {
  z-index: 1;
  display: flex;
  align-items: center;
}

.Edge-start {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 160px;
  //transform: skew(-45deg, 0deg);
  //transform-origin: top right;
  //border-bottom-right-radius: 30px;

  @include sm {
    width: 100%;
    height: 96px;
  }

  @include xxl {
    width: 55%;
  }
}

.Edge-end {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 160px;
  //transform: skew(-45deg, 0deg);
  //transform-origin: bottom left;
  //border-top-left-radius: 30px;

  @include sm {
    display: none;
  }

  @include xxl {
    width: 45%;
  }
}

.Edge-content-end-text {
  font-size: 18px;
  line-height: 23px;
  width: 38%;

  @include sm {
    padding-top: 24px;
    width: 100%;
  }

  @include md {
  }

  @include xxl {
    font-size: 22px;
    line-height: 29px;
  }
}

.Edge-pagination {
  font-size: 18px;
  line-height: 23px;

  @include sm {
    display: none;
  }
}

.Edge-pagination-icon {
  padding-left: 25px;
  cursor: pointer;

  @include sm {
    display: none;
  }
}

.Icon-wrap {
  display: flex;
  align-items: center;

  svg {
    width: 62px;

    @include sm {
      width: 40px;
    }
  }
}

.Edge-title {
  font-size: 24px;
  line-height: normal;
  font-weight: 500;
}

.Icon-label {
  font-size: 24px;
  line-height: 49px;
  font-weight: 500;
  padding-left: 25px;

  @include sm {
    padding-left: 15px;
  }
}

.Edge-icon-text {
  display: flex;
  justify-content: center;
  font-size: 32px;
  color: #C4C4C4;
  text-decoration: none;

  @include sm {
    font-size: 18px;
  }
}

.Edge-icon-link {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 16px;
  font-weight: 400;
  color: $green;
  cursor: pointer;
  text-decoration: none;
}
