@import "../../scss/utils";

.Slogan {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
}

.Slogan-title {
  font-size: 64px;
  line-height: 83px;
  color: $light;

  @include sm {
    font-size: 40px;
    line-height: 52px;
  }
}

.Page-down {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 70px;
  z-index: 2;

  @include sm {
    display: none;
  }
}
