@import "../../scss/utils";

.Contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Contacts-section {
}

.Contacts {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Contact-item {
  border: 1px solid $grey-50;
  background: $black;
  border-radius: 24px;
  padding: 52px;

  @include md {
    padding: 32px;
  }

  @include sm {
    padding: 24px;
  }
}

.Contact-item-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.Contact-item-title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  padding-left: 20px;
  color: $light-steal;

  @include md {
    font-size: 18px;
    line-height: 23px;
  }
}

.Contact-item-info {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 24px;
  color: $grey;

  @include sm {
    font-size: 16px;
    line-height: 21px;
    padding-top: 20px;
  }
}


