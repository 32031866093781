@import "../../scss/utils";

.Header {
  position: fixed;
  height: 110px;
  width: 100vw;
  display: flex;
  align-items: center;
  color: $green;
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Logo-icon {
    display: none;
  }

  @include sm {
    .Logo {
      display: none;
    }

    .Logo-icon {
      display: block;
    }
  }

}

.nav {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  .nav-item {
    height: 32px;
    padding: 0 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $grey;

    &:hover {
      color: $green;
    }
  }

  @include sm {
    display: none;
  }
}

.hide-on-mobile {
  @include sm {
    display: none !important;
  }
}

.Menu-wrap {
  z-index: 2;
  display: none;
  cursor: pointer;

  @include sm {
    display: block;
  }
}

.Menu-close {
  display: none;
}

.Header-collapse {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;

  @include md {
    display: none;
  }
}

.Header-collapse-menu {
  display: none;
  overflow: hidden;
  position: absolute;
  background: $black;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  top: 0;
  z-index: 1;

  .nav {
    display: block;
    text-align: center;
  }

  .nav-item {
    padding: 20px 0;
    display: block;
  }

  .nav-item-btn {
    box-sizing: unset;
    padding: 20px 0;
    margin: 0;
    border: none;
  }
}
