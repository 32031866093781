@import "utils";

.g-32px {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 32px;
}

.pl-18 {
  padding-left: 18px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-15 {
  padding-left: 15px !important;
}

.line-h-normal {
  line-height: normal !important;
}

.w-full {
  width: 100%;
}

.sm-hidden {
  @include sm {
    display: none !important;
  }
}

.md-hidden {
  @include sm {
    display: block !important;
  }

  @include md {
    display: none !important;
  }
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.zero-left-right {
  left: 0 !important;
  right: 0 !important;
}
